import Header from '../Header/Header';
import Carousel from './Carousel';  // Importa el carrusel
import Statistics from './Statistics';
import Logos from './Logos';
import './Home.css';
import imagen_wot from '../../img/WoT.png';

export default function Home() {

  return (
    <div>
      <Header />
      <div>
      <div className='bienvenida'>
          BIENVENIDA A WOMAN TALENT
      </div>
      <div className="description-home">
        <h2>Tres cosas que debes saber antes de comenzar usando nuestra plataforma:</h2>
      </div>

      </div>
      
     

      
      <Carousel />
      <hr className="divider" />
    </div>
  );
}

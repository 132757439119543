// import { useState, useEffect } from 'react';
// import './Carousel.css';  // Archivo CSS para estilos
// import carrusel1 from '../../img/carrusel_1.jpg';
// import carrusel2 from '../../img/carrusel_2.jpg';
// import carrusel3 from '../../img/carrusel_3.jpg';
// import imagen_wot from '../../img/WoT.png';

// const images = [
//   carrusel1,
//   carrusel2,
//   carrusel3
// ];
// const texts = [
//   'Plataforma que conecta talento femenino de forma directa y visibiliza a las mujeres en empresas y organizaciones.',
//   'Sin sesgos, perfiles ciegos y ajustes según experiencia para las búsquedas.',
//   'Hoy más de 3000 mujeres son usuarias de Woman Talent.'
// ];


// export default function Carousel() {
//   const [currentIndex, setCurrentIndex] = useState(0);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
//     }, 7000); // Cambia de imagen cada 3 segundos
//     return () => clearInterval(interval);
//   }, []);

//   return (
//     <div className="carousel">
//       <div className="slide_carousel">
//         <img
//           src={images[currentIndex]}
//           alt="Carousel"
//           className="image_carousel"
//         />
//         <div className="overlay_carousel">
//           <p className="overlay-text_carousel">
//             {texts[currentIndex]} {/* Cambia el texto según la imagen */}
//           </p>
          
//         </div>
//       </div>
//     </div>
//   );
// }
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './Carousel.css';
import carrusel1 from '../../img/carrusel_1.jpg';
import carrusel2 from '../../img/carrusel_2.jpg';
import carrusel3 from '../../img/carrusel_3.jpg';

const images = [
  carrusel1,
  carrusel2,
  carrusel3
];

const stats = [
  {
    titleNumber: "1.",
    title: "MANTÉN SIEMPRE TU INFORMACIÓN ACTUALIZADA",
    description: "Mantén tu perfil completo y actualizado para que el algoritmo de búsqueda pueda encontrar las mejores coincidencias con las oportunidades laborales ideales para ti.",
  },
  {
    titleNumber: "2.",
    title: "USAMOS UN ALGORITMO DE MATCH PERSONALIZADO",
    description: "Conectamos a las mujeres que cumplen con el perfil solicitado en las empresas mediante un algoritmo que hace match con las mejores candidatas según la información que nos dan.",
  },
  {
    titleNumber: "3.",
    title: "SOMOS MÁS DE 3000 USUARIAS, COMPARTE NUESTRA RED CON MÁS MUJERES",
    description: "Formamos una comunidad creciente de mujeres talentosas en busca de nuevas oportunidades, ayúdanos a compartirla con más mujeres!",
  },
];

export default function Carousel() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  return (
    <div className="carousel">
      <div className="slide_carousel">
        <img
          src={images[currentIndex]}
          alt="Carousel"
          className="image_carousel"
        />
        <div className="overlay_carousel">
        <h3 className="overlay-title-number_carousel">
            {stats[currentIndex].titleNumber}
          </h3>
          <h3 className="overlay-title_carousel">
             {stats[currentIndex].title}
          </h3>
          <h3 className="overlay-description_carousel">
            {stats[currentIndex].description}
          </h3>
          {currentIndex === images.length - 1 ? (
            <button 
              className="btn-completar-formulario-home" 
              onClick={() => navigate('/form')}
            >
              Ir a completar información
            </button>
          ) : (
            <button 
              className="btn-completar-formulario-home" 
              onClick={handleNext}
            >
              Siguiente
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
